<template>
  <pinch-zoom disable-zoom-control="disable">
    <v-carousel
      height="auto"
      show-arrows-on-hover
      hide-delimiter-background
    >
      <v-carousel-item
        v-for="(imagem, i) in imagens"
        :key="i"
      >
        <v-img
          v-if="!imagensErro.includes(i)"
          :src="caminhoImagemLeitura + imagem.caminho_imagem"
          height="100%"
          @load="carregandoImagem = false"
          @error="$emit('erro-carregar-imagem', i)"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              />
            </v-row>
          </template>
        </v-img>

        <v-img
          v-else
          height="100%"
          :src="require('@/assets/imagens/imagem-padrao.png')"
          :lazy-src="$store.state.layout.imagemLeituraCarregando"
        />
      </v-carousel-item>
    </v-carousel>
  </pinch-zoom>
</template>

<script>
  import PinchZoom from 'vue-pinch-zoom'

  export default {
    components: {
      PinchZoom,
    },

    props: {
      imagens: {
        type: Array,
        default: () => [],
      },
      imagensErro: {
        type: Array,
        default: () => [],
      },
    },

    data () {
      return {
        caminhoImagemLeitura: process.env.VUE_APP_URL_SERVIDOR,
        carregandoImagem: true,
      }
    },

    methods: {

    },
  }
</script>
